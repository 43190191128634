.skipbutton{
  font-size: 1rem;
  padding: 0.4rem 0.7rem 0.4rem 0.8rem;
  font-weight: 600;
  color: black;
  background-color: white;
  border-radius: 2rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.15rem;
  height: 2.5rem;
  gap: 0.2rem;

  appearance: none;
  border: none;

  font-family: var(--f-sans);

  text-decoration: none;
}
.skipbutton:hover{

  color: white;
  background-color: black;
  cursor: pointer;
}

.skipbutton:hover svg{
  fill: white;
}



.skipbutton svg{
  height: 1.7rem;
  width: 1.7rem;
  margin-left: 0.25rem;
  fill: black;
}



@media screen and (max-width: 580px) /* Mobile */ {
  .widgetButton{
    display: none !important;
  }
}